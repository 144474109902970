import React from 'react';
import Slider from "./toolsSlider";
import { StaticQuery, graphql } from "gatsby"

const tools = () => (
    <StaticQuery
    query={graphql`
      {
        allWpPage(filter: {slug: {eq: "home-dev"}}) {
          nodes {
            home {
              toolsOfTheTradeTitle
              toolsOfTheTradeSubtitle
            }
          }
        }
        allWpTool(sort: {fields: date, order: DESC}) {
          nodes {
            slug
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
          }
        }
      }
    `}
    render={data => {
      const pageContent = data.allWpPage.nodes[0].home;
      const postContent = data.allWpTool.nodes;
      return (
        <div className="pb-8 overflow-hidden">
            <div className="container">
                <div className="row justify-content-center text-center mb-md-5 mb-4 pb-md-3">
                    <div className="col-sm-11 col-md-9 col-lg-7 col-xxl-8">
                        <h2 className='h1' data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: pageContent?.toolsOfTheTradeTitle}} />
                        <p  data-aos="fade-up" data-aos-delay="400">{pageContent?.toolsOfTheTradeSubtitle}</p>
                    </div>
                </div>
            </div>

            <Slider posts={postContent} />
        </div>
    );
}}
></StaticQuery>
);

export default tools;
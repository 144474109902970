import { graphql } from "gatsby"
import * as React from "react"
import Banner from "../components/homeBanner"
import Growth from "../components/homeGrowth"
import Services from "../components/homeServices"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Partnership from "../components/partnership"
import Seo from "../components/seo"
import Thoughts from "../components/thoughts"
import Tools from "../components/tools"

const IndexPage = ({data}) => {
  const pageContent = data.allWpPage.nodes[0];
 return (
   <Layout>
     <Seo schema={true} image="https://dev.alkye.com/wp-content/uploads/2022/07/Alkye-Services.png" title={pageContent?.seo.title} description={pageContent?.seo.metaDesc} focuskw={pageContent?.seo.focuskw} />
     
     <Banner />
     <Growth />
     <Partnership />
     <Services />
     <Thoughts />
     <Tools />
   </Layout>
 )
}

export default IndexPage

export const query = graphql`{
  allWpPage(filter: {slug: {eq: "home-dev"}}) {
    nodes {
      seo {
        title
        metaDesc
        focuskw
      }
    }
  }
}`
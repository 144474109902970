import React from 'react';
import { StaticQuery, graphql } from "gatsby"

const homeBanner = () => (
<StaticQuery
    query={graphql`
    {
        allWpPage(filter: {slug: {eq: "home-dev"}}) {
          nodes {
            home {
              createText
              growText
              ideateText
              bannerSubheading
            }
          }
        }
      }
    `}
    render={data => {
        const pageContent = data.allWpPage.nodes[0].home;
        return (
            <div className="page-intro text-center ">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-7 col-xl-8 col-xxl-9">
                        <h1 className="title"><span data-aos="fade-in" data-aos-delay="200">{pageContent?.ideateText && pageContent?.ideateText}.</span> <em data-aos="fade-in" data-aos-delay="300">{pageContent?.createText && pageContent?.createText}.</em> <span data-aos="fade-in" data-aos-delay="400">{pageContent?.growText && pageContent?.growText}.</span></h1>
                        {/* <h1 className="title" dangerouslySetInnerHTML={{__html: pageContent?.bannerHeading}} /> */}
                        <div data-aos="fade-in" data-aos-delay="500">
                            <p>{pageContent?.bannerSubheading}</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }}
    ></StaticQuery>
);

export default homeBanner;


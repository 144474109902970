import React from 'react';
import { StaticQuery, graphql } from "gatsby"

const homeGrowth = () => (
    <StaticQuery
    query={graphql`
      {
        allWpPage(filter: {slug: {eq: "home-dev"}}) {
          nodes {
            home {
              awardSectionTitle
              awardSectionSubtitle
              awardLogosList {
                awardLogoImagesItem {
                  mediaItemUrl
                  altText
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
        const pageContent = data.allWpPage.nodes[0].home;
        const [scroll, setScroll] = React.useState(false);
        
        React.useEffect(() => {
          window.addEventListener("scroll", () => {
            setScroll(window.scrollY > window.innerHeight / 4)
          })
        }, [])

        const logos = [
          "https://dev.alkye.com/wp-content/uploads/2022/09/iso-logo-1.webp",
          "https://dev.alkye.com/wp-content/uploads/2022/09/webby_winner.webp",
          "https://dev.alkye.com/wp-content/uploads/2022/09/b-certified.webp",
          "https://dev.alkye.com/wp-content/uploads/2022/09/awwwards-1.webp"
        ]

        return(
            <div className="section-growth position-relative text-white py-120">
                <div className="section-growth-overlay" style={{ width: scroll ? '100%' : '96%' }}></div>
                <div className="container">
                    <div className="row mb-5 pb-md-4">
                    <div className="col-sm-8 col-md-6 mb-4 col-xxl-5 col-lg-5"  data-aos="fade-up" data-aos-delay="200">
                        <h2 className='h1' dangerouslySetInnerHTML={{__html: pageContent?.awardSectionTitle}} />
                    </div>
                    <div className="col-md-12 col-lg-11 col-xxl-10"  data-aos="fade-up" data-aos-delay="200">
                        <p>{pageContent?.awardSectionSubtitle}</p>
                    </div>
                    </div>

                    <div className="row text-center align-items-center" data-aos="fade-up" data-aos-delay="200">
                        {pageContent?.awardLogosList && pageContent?.awardLogosList?.map((logo, index) => (
                            <div className="col-6 col-sm-3 mb-sm-0 mb-3 col-md-3 col-lg-2" key={index}>
                                <img loading="lazy" width="158" height="145" src={logo.awardLogoImagesItem?.mediaItemUrl ? logo.awardLogoImagesItem?.mediaItemUrl : logos[index] } className="gatsby-image-wrapper img-fluid" alt={logo.awardLogoImagesItem?.altText} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }}
  ></StaticQuery>
);

export default homeGrowth;
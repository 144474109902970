import React from 'react';
import { StaticQuery, graphql, Link } from "gatsby"

const homeServices = () => (
    <StaticQuery
    query={graphql`
      {
        allWpPage(filter: {slug: {eq: "home-dev"}}) {
            nodes {
              home {
                serviceSectionTitle
                serviceSectionSubTitle
                homePageServices {
                  buttonName
                  buttonUrl
                  heading
                  subheading
                  image {
                    altText
                    mediaItemUrl
                    mediaDetails {
                        sizes {
                          sourceUrl
                        }
                    }
                  }
                }
              }
            }
        }
      }
    `}
    render={data => {
        const pageContent = data.allWpPage.nodes[0].home;

    return (
        <div className="py-0">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-md-10 col-lg-8 col-xxl-9">
                        <h2 className='h1' data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: pageContent?.serviceSectionTitle}} />
                        <p  data-aos="fade-up" data-aos-delay="400">{pageContent?.serviceSectionSubTitle}</p>
                    </div>
                </div>
            </div>
            
            <div className="services-list mt-3 pb-4 pt-5">
                <div className="container container-md">
                    <div className="row justify-content-between">
                        {pageContent?.homePageServices && pageContent?.homePageServices.map((service, index) => (
                            <div className={`col-md-6 d-flex ${index % 2 ? 'justify-content-end' : ''}`}  data-aos="fade-up" data-aos-delay={index * 200} key={index}>
                                <div className="service-card">
                                    <Link to="/services" aria-label="Services" className="d-block">
                                        <div className="service-card-image">
                                            {/* <img loading="lazy" src={service.image.mediaItemUrl} alt={service.image.altText} className="w-100 d-block gatsby-image-wrapper" /> */}
                                            <picture>
                                                {/* <source media="(min-width:1800px)" srcSet={gal.galleryImage.node?.mediaDetails.sizes[5]?.sourceUrl} /> */}
                                                {/* <source media="(min-width:1280px)" srcSet={gal.galleryImage.node?.mediaDetails.sizes[4]?.sourceUrl} /> */}
                                                {/* <source media="(min-width:992px)" srcSet={gal.galleryImage.node?.mediaDetails.sizes[1]?.sourceUrl} /> */}
                                                <source media="(min-width:575px)" srcSet={service.image?.mediaItemUrl} />
                                                <img loading="lazy" width="443" height="553" src={service.image?.mediaDetails.sizes[0]?.sourceUrl} alt={service.image?.altText} className="w-100 d-block gatsby-image-wrapper" />
                                            </picture>
                                        </div>
                                        <h3 dangerouslySetInnerHTML={{__html: service.heading}} />
                                        <p className="lead">{service.subheading}</p>
                                        <span className="btn text-uppercase btn-link d-inline-flex align-items-center">
                                            {service.buttonName}

                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                            </svg>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            </div>
    );
}}
    ></StaticQuery>
);

export default homeServices;
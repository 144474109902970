import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import PartnershipsSlider from './partnershipsSlider';

const partnership = () => (
    <StaticQuery
    query={graphql`
      {
        allWpPage(filter: {slug: {eq: "home-dev"}}) {
          nodes {
            home {
              partnershipSectionSubtitle
              partnershipSectionTitle
              listOfPartnerships {
                ... on WpPartnership {
                  title
                  content
                  featuredImage {
                    node {
                      altText
                      mediaItemUrl
                      mediaDetails {
                        sizes {
                          sourceUrl
                        }
                      }
                    }
                  }
                  partnership {
                    partnerImage {
                      altText
                      mediaItemUrl
                      mediaDetails {
                        sizes {
                          sourceUrl
                          width
                        }
                      }
                    }
                    partnerName
                    designation
                    quote
                    urlOfCaseStydy
                  }
                }
              }
            }
          }
        }
        allWpPartnership {
          nodes {
            title
            content
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
            partnership {
              partnerImage {
                altText
                mediaItemUrl
                mediaDetails {
                  sizes {
                    sourceUrl
                    width
                  }
                }
              }
              partnerName
              designation
              quote
              urlOfCaseStydy
            }
          }
        }
      }
    `}
    render={data => {
        const pageContent = data.allWpPage.nodes[0].home;
        // const postContent = data.allWpPartnership.nodes;

        return (
            <div className="py-120 overflow-hidden">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-12">
                            <h2 className='h1'  data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: pageContent?.partnershipSectionTitle}} />
                            <p  data-aos="fade-up" data-aos-delay="400">{pageContent?.partnershipSectionSubtitle}</p>
                        </div>
                    </div>

                    {pageContent?.listOfPartnerships?.length > 0 && <PartnershipsSlider posts={pageContent?.listOfPartnerships} />}
                </div>
            </div>
        );
    }}
    ></StaticQuery>
);

export default partnership;